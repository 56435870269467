import { isEmpty } from "lodash";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import styles from "../assets/newCss/sky.css";
import { Helmet } from "react-helmet";
import Aboutus from "../components/staticContent/Aboutus";
import GeneralTerms from "../components/staticContent/GeneralTerms";
import Kyc from "../components/staticContent/Kyc";
import PrivacyPolicy from "../components/staticContent/PrivacyPolicy";
import RulesRegulation from "../components/staticContent/RulesRegulation";
import ResponsibleGaming from "../components/staticContent/ResponsibleGaming";
function LoginMobile(props) {
  let {
    loginUser,
    isLoged,
    user,
   
    content,
    slug,
    setSlug,
    handleContentToggle,
  } = useContext(AuthProvider);
  const navigate = useNavigate();
 
  const [open, setOpen] = useState("tab2");

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    unregister,
    reset: reset2,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });
  const [getValidateCode, setValidateCode] = useState("");
  const [data, setData] = useState({});
  const onSubmit2 = (data) => {
    // if (parseInt(data.validateCode) === parseInt(getValidateCode)) {
      loginUser(data);
    // }
  };
  // const canvasRef = useRef(null);
  // const changeCode = () => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  //   context.font = "bold 120px sans-serif";
  //   const code = Math.floor(1000 + Math.random() * 9000);
  //   context.clearRect(0, 0, canvas.width, canvas.height);
  //   context.fillText(code, 0, 130);
  //   setValidateCode(code);
  // };

  const getSetting = async (event) => {
    try {
      const { status, data: response_users } = await apiGet(apiPath.getSetting);
      if (status === 200) {
        if (response_users.success) {
          setData(response_users.results);
        }
      }
    } catch (err) {}
  };
  useEffect(() => {
    // changeCode();
    getSetting();
  }, []);
  useEffect(() => {
    // console.log('%cLogin.js line:43 user', 'color: #007acc;', user);
    if (user !== null) {
      // console.log("MMMM")
      // window.location.reload();
      navigate("/");
    }
  }, [user]);

  return (
    <div className="newLogin">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
        />
      </Helmet>

      <div className="login_main" style={{ height: "100vh" }}>
        <header className="login-head">
          {/* <Link to="/" className="close"></Link> */}
          {/* <h1></h1> */}
          {/* <div id="powerWrap" className="betfair-wrap">
            <p>Powered by</p>
            <span>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/betfair_black.png"
                }
                alt=""
                draggable="false"
              />
            </span>
          </div> */}
        </header>
        <Form onSubmit={handleSubmit2(onSubmit2)}>
          <dl className="form-login">
            <Form.Group
              style={{ marginBottom: "10px" }}
              id="loginNameErrorClass"
            >
              <Form.Control
                type="text"
                autocomplete="off"
                // id="loginName"
                className={errors2.username ? "is-invalid" : ""}
                {...register2("username", {
                  required: "Please enter Username",
                })}
                placeholder="Username"
              />
              {errors2.username && errors2?.username?.message && (
                <label className="invalid-feedback text-left">
                  {errors2.username.message}
                </label>
              )}
            </Form.Group>
            <Form.Group
              style={{ marginBottom: "10px" }}
              id="passwordErrorClass"
            >
              <Form.Control
                type="password"
                autocomplete="off"
                // id="password"
                className={errors2.password ? "is-invalid" : ""}
                {...register2("password", {
                  required: "Please enter password",
                })}
                data-role="none"
                placeholder="Password"
              />
              {errors2.password && errors2.password.message && (
                <label className="invalid-feedback text-left">
                  {errors2.password.message}
                </label>
              )}
            </Form.Group>
            {/* <dd id="validCodeErrorClass" style={{ display: "block" }}>
              <input
                type="number"
                keyboardType="numeric"
                autocomplete="off"
                maxLength="4"
                className={errors2.validateCode ? "is-invalid" : ""}
                {...register2("validateCode", {
                  required: "Please enter validate code",
                  validate: {
                    validate: (value) =>
                      parseInt(value) === parseInt(getValidateCode) ||
                      "Invalid validate code",
                  },
                })}
                onChange={(e) => {
                  if (e.target.value.length == 4) {
                    e.target.blur();
                    unregister("validateCode", { keepValue: true });
                  }
                }}
               
                id="validCode"
                placeholder="Validation Code"
               
              />
              
              <canvas
                ref={canvasRef}
                onClick={changeCode}
                className="inputcanvas"
                id="authenticateImage"
              />

              {errors2.validateCode && errors2.validateCode.message && (
                <label className="invalid-feedback text-left">
                  {errors2.validateCode.message}
                </label>
              )}
            </dd> */}
           
            <dd>
              <Button type="submit" className="btn-send w-100" id="loginBtn">
                Login
              </Button>
            </dd>
            {isLoged == "failed" && (
              <dd id="errorMsg" class="state">
                Login name or password is invalid! Please try again.
              </dd>
            )}
          </dl>
        </Form>

        <ul className="policy-link" style={{ display: "block" }}>
          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("about");
              }}
            >
              About us
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("term");
              }}
            >
              Terms and Conditions
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("kyc");
              }}
            >
              KYC
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("privacy");
              }}
            >
              Privacy Policy
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("rules");
              }}
            >
              Rules and Regulations
            </Link>
          </li>

          <li>
            <Link
              to="javascript:void(0)"
              onClick={() => {
                handleContentToggle();
                setSlug("responsible");
              }}
            >
              Responsible Gaming
            </Link>
          </li>
          {/* <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Self-Exclusion Policy</a>
            </li>
            <li>
              <a href="#">Underage Policy</a>
            </li> */}
        </ul>

        {/* static-content */}

        {content && (
          <div className="frame-wrap">
            <div className="news-wrap news-wrap-frame">
              {slug === "about" && <Aboutus />}
              {slug === "term" && <GeneralTerms />}
              {slug === "kyc" && <Kyc />}
              {slug === "privacy" && <PrivacyPolicy />}
              {slug === "rules" && <RulesRegulation />}
              {slug === "responsible" && <ResponsibleGaming />}

              <footer style={{ textAlign: "center" }}>
                <Link
                  to="#"
                  className="btn-send login-mobile-btn"
                  onClick={() => {
                    handleContentToggle();
                    setSlug("");
                  }}
                >
                  CLOSE
                </Link>
              </footer>
            </div>
          </div>
        )}

        {/* static-content */}

        <div id="supportWrap" className="support-wrap mb-0 pb-0 login-support">
          <div className="support-service">
            {/* <Link
              id="support_whatsapp"
              to="javascript:void(0)"
              onClick={() => setOpen("tab1")}
              className={open == "tab1" ? "support-mail open" : "support-mail "}
            
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "assets/images/transparent.gif"
                }
                title="WhatsApp"
                alt=""
                className="support-mail"
              />
            </Link> */}
            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab2")}
              className={
                open == "tab2" ? "support-whatsapp open" : "support-whatsapp "
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "assets/images/transparent.gif"
                }
                alt=""
                title="WhatsApp"
                className="support-whatsapp"
              />
            </Link>

            {/* <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab3")}
              className={
                open == "tab3" ? "support-telegram open" : "support-telegram "
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "assets/images/transparent.gif"
                }
                alt=""
                title="WhatsApp"
                className="support-telegram"
              />
            </Link>

            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab4")}
              className={
                open == "tab4"
                  ? "support-skype open"
                  : "support-skype text-white"
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "assets/images/transparent.gif"
                }
                alt=""
                title="Skype"
                className="support-skype"
              />
            </Link> */}
          </div>

          <div class="support-info">
            {open == "tab1" && (
              <div>
                <Link
                  id="emailContent"
                  className="ui-link text-white"
                  target="_blank"
                  to="mailto:https://betxwin.live"
                >
                  {/* https://betxwin.live */}
                </Link>
              </div>
            )}
            {open == "tab2" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                    target="_blank"
                    // to="https://Wa.me/+12183822142"
                  >
                   {/* +12183822142 */}
                  </Link>
                </div>
              </div>
            )}
            {open == "tab3" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                    target="_blank"
                    to="mailto:https://betxwin.live"
                  >
                    {/* https://betxwin.live */}
                  </Link>
                </div>
              </div>
            )}
            {open == "tab4" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                    target="_blank"
                    to="mailto:https://betxwin.live"
                  >
                    {/* https://betxwin.live */}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginMobile;
